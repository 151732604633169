import { getDevice } from '../utils'

const getUnitCode = (domElement) => {
    let adUnitCode = null;
    if (domElement) {
        const adUnit = domElement.getAttribute('data-ad-unit') || domElement.getAttribute('data-slot') || domElement.getAttribute('data-adunit')
        adUnitCode = adUnit.split('/').pop() // get only last element for looking up in unitMap
    }
    return adUnitCode
};

export const createSlotConfig = (slotID, force = false, domElement = null)  => {

    if (!domElement) {
        domElement = document.getElementById(slotID)
    }
    if (!domElement) {
        return null
    }
    const slotType = domElement.dataset.type || 'gam';

    const unit = getUnitCode(domElement);
    
    let slotConfig = null

    if (unit) {
        slotConfig = {
            "devices": [
                getDevice()
            ],
            "force": force,
            "type": slotType,
            "frequencyCap": {
                "hourly": 0,
                "daily": 0
            },
            "floors": {
                "mobile": 0,
                "desktop": 0
            },
            "unitMob": unit,
            "unit": unit
        };
    }
    
    return slotConfig;
};