import { BaseSlot } from './base';

export class IntextToVipSlot extends BaseSlot {

    type = 'intexttovip'

    init(force) {
        
        const res = super.init(force);

        if (!res) {
            return
        }

        document.addEventListener('vfluxOnAdEnd',  ev => {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `ads ended for VIP #${this.id} triggering onClose`);
            clearTimeout(this.timeoutId);
            this.onClose();
        });
        
        this.state = 'requested';

        this.onDefined();

        this.onRequested();

        return this
    }

    onDefined() {
        const cEvent = new CustomEvent("GLOBAL_NAME_slot_defined", { detail: this.getSlotDetailForEvent() });
        document.dispatchEvent(cEvent);
    }

}