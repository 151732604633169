import { createSlotConfig } from './slotconfig'

export class SlotFactory {
    constructor() {
        this.types = new Map();
    }

    register(type, ClassConstructor) {
        this.types.set(type, ClassConstructor);
    }

    create(slotID, slotConfig, manager, config) {

        if (!slotConfig) {
            // get slotConfig either from the global config or builld one from a static method
            if (slotID in config.slots) {
                slotConfig = config.slots[slotID]
            } else {
                // Build default slotConfig
                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `need a default slot config for ${slotID}`);
                slotConfig = createSlotConfig(slotID);
            }
            if (!slotConfig) {
                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `could not create a slot config for ${slotID} - bailing`);
                return null;
            }
        }
        const ClassConstructor = this.types.get(slotConfig.type);
        if (!ClassConstructor) {
            throw new Error(`Unknown ad slot type: ${slotConfig.type}`);
        }
        return new ClassConstructor(slotID, slotConfig, manager, config);
    }
}