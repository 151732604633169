import { SlotFactory } from './factory';
import { isMobile, getDevice, getCurrentPageType, detectPageType, getPageTypeName, getIntextConfig, isSubscriber } from '../utils'

export class AdManager {
    gamSlots;
    slots;
    units;
    gamRefreshQueue;
    pbUnits = [];
    apsUnits = [];
    initialized = false;
    refreshObserver = null;
    unitsByPath;
    dynamicIDCounter = 0;
    
    constructor(config) {
        this.factory = new SlotFactory();
        this.slots = new Map();
        this.units = new Map(Object.entries(config.units));
        this.unitsByPath = new Map(
            Object.values(config.units).map(item => [item.path, item])
        );
        this.slotData = new Map(Object.entries(config.slots));
        this.formatData = new Map(Object.entries(config.formats))
        this.gamSlots = new Map();
        this.postSlotInitEvents = [];
    }

    initialize(slotTypes) {
        if (this.initialized) return;
        
        for (const [type, implementation] of Object.entries(slotTypes)) {
            this.factory.register(type, implementation);
        }
        
        this.initialized = true;
    }

    getSlotConfig(adUnitCode) {
        const slotConfig = {
            "devices": [getDevice()],
            "force": false,
            "type": "gam",
            "frequencyCap": {
                "hourly": 0,
                "daily": 0
            },
            "unitMob": isMobile() ? adUnitCode : null,
            "unit": isMobile() ? null : adUnitCode
        };
        return slotConfig;
    }

    addAdUnitInfo(elem, elementClass, adUnitCode) {
        elem.setAttribute("data-adunit", adUnitCode);
        elem.classList.add(elementClass);
        elem.style.display = 'block';
        
        /**
         * If needed, setup the slot data for use by the the GamSlot class
         */
        if (!this.slotData.has(elem.id)) {
            const slotConfig = this.getSlotConfig(adUnitCode);
            slotConfig.format = 'box-intext';
            this.slotData.set(elem.id, slotConfig)
        }
    }

    /**
     * 
     * @param {int} index 
     * @param {object} intextConfig
     * @param {string} elementClass
     * 
     * @todo move this to the IntextSlot component
     */
    generateIntextAdElement(index, intextConfig, elementClass) {
        const containerElement = document.createElement('div')
        const idPrefix = `div-${elementClass}-intext`
        const defaultIntextAd = intextConfig.defaultAdUnit;
        const customCSS = intextConfig.customCSS || "min-width:300px;min-height:250px;background-color:#f2f2f2;margin: 8px auto; text-align: center; display: block; clear: both;";
        
        containerElement.id = `${idPrefix}-container-${index}`;
        containerElement.setAttribute("style", customCSS);

        const adElement = document.createElement('div');
        adElement.id = `${idPrefix}-gam-${index}`;
        
        const adList  = intextConfig.slots;
        if (index < adList.length) {
            const elemToInsert = adList[index];
            window.GLOBAL_OBJECT.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'inserting intext element:', elemToInsert);
            if (elemToInsert.adslot) {
                adElement.id = elemToInsert.adslot;
            } else if (elemToInsert.ad_unit) {
                this.addAdUnitInfo(adElement, elementClass, elemToInsert.ad_unit);
                adElement.classList.add(`${elementClass}-intext`, `${elementClass}-300x250`);
                if (elemToInsert.fallbackSlot) {
                    adElement.dataset.fallbackSlot = elemToInsert.fallbackSlot
                }
            }
        } else {
            window.GLOBAL_OBJECT.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'inserting default intext unit:', defaultIntextAd);
            this.addAdUnitInfo(adElement, elementClass, defaultIntextAd);
            adElement.classList.add(`${elementClass}-intext`, `${elementClass}-300x250`);
        }
        window.GLOBAL_OBJECT.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'intext ad element:', adElement, "- container:", containerElement);
        containerElement.appendChild(adElement);
        return containerElement;
    };

    insertIntext(config, intextSelector) {
        const pageType = getCurrentPageType()
        if (typeof intextSelector === 'undefined') {
            intextSelector = pageType.intextSelector;
        }
        if (!intextSelector) {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'no intext selector for page tye:', pageType);
            return
        }
        const intextConfig = getIntextConfig(config);
        if (!intextConfig) {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'no config for intext');
            return
        }
        const intextElement =  document.querySelector(intextSelector)
        if (!intextElement) {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'intext selector not found:', intextSelector);
            return
        }
        
        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'inserting intext adv in element:', intextElement);

        const distances = intextConfig.distances;
        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'intext distances:', distances);
        const ele_type = intextConfig.elementType;
        
        const textContainer = document.querySelector(intextSelector);
        if (!textContainer) {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'intext container not found');
            return
        }
        let elementChildren = Array.from(textContainer.children).filter(child => child.tagName.toLowerCase() === ele_type);
        if (!elementChildren || elementChildren.length == 0) {
            elementChildren = textContainer.querySelectorAll(ele_type);
        }
        if (!elementChildren || elementChildren.length == 0) {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'no candidate positions found for intext adv');
            return
        }

        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'intext container children:', elementChildren);
        
        const finalPlacement = [];
        const articleBottom = intextElement.offsetTop + intextElement.offsetHeight;

        for(const elem of elementChildren) {
            const elementBottom = elem.offsetTop + elem.offsetHeight;
            const bottomOffset = elementBottom - intextElement.offsetTop;

            // config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'doing element:', elem, "- bottom:", elementBottom, "- bottom offset:", bottomOffset);

            if (articleBottom - elementBottom < distances.bottom) {
                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'no more space for intext');
                break;
            }
            if (finalPlacement.length === 0) { // first element
                if (bottomOffset > distances.first) {
                    const elementContent = document.createElement(ele_type);
                    elementContent.classList.add(`intext-element-${1}`);
                    const placementInfo = {
                        target: elem,
                        content: this.generateIntextAdElement(finalPlacement.length, intextConfig, config.class),
                        bottomOffset: bottomOffset,
                    };
                    // config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'placement info:', placementInfo);
                    finalPlacement.push(placementInfo);
                }
            } else {
                const previousAd = finalPlacement[finalPlacement.length - 1];
                if (bottomOffset - previousAd.bottomOffset > distances.next) {
                    const placementInfo = {
                        target: elem,
                        content: this.generateIntextAdElement(finalPlacement.length, intextConfig, config.class),
                        bottomOffset: bottomOffset,
                    };
                    // config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'default placement info:', placementInfo);
                    finalPlacement.push(placementInfo);
                }
            }
        }

        // config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "Intext final placement:", finalPlacement);
        //  Inject ads into DOM
        for (const item of finalPlacement) {
            // config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "Inserting", item.content, "after", item.target);
            item.target.parentNode.insertBefore(item.content, item.target.nextSibling);
        };
    }

    setupSlots(config) {

        /**
         * @todo check if this logic is the correct one or if we might want to show some intext adv to subscribers
         */
        if (!isSubscriber()) {
            this.insertIntext(config);
        }

        for (const [adSlotId, slotConfig] of this.slotData.entries()) {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `doing ad slots ${adSlotId}:`, slotConfig);
            try {
                this.createSlot(adSlotId, slotConfig, config);
            } catch (error) {
                console.warn("[GLOBAL_NAME] error setting up slot", adSlotId, "-", error);
            }
        }
        this.runPostSlotInitEvents();
    }

    createSlot(elementId, slotConfig, config) {
        
        if (!this.initialized) {
            throw new Error('AdManager must be initialized before creating slots');
        }
        
        const slot = this.factory.create(elementId, slotConfig, this, config);

        if (!slot) {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "slot creation failed for:", elementId);
            return;
        }
        
        /**
         * @todo make sure that no further action is taken if this slot is not to be shown
         */
        if (slot.isToBeShown()) {
            const res = slot.init();
            if (res) {
                this.slots.set(elementId, slot);
                return slot;
            } else {
                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "could not set up slot:", elementId);
            }
        } else {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "slot not to be shown:", elementId);
        }
        
    }

    getGAMUnitInfo(unitName) {
        let unit = this.units.get(unitName)
        if (!unit) {
            unit = this.unitsByPath.get(unitName)
        }
        return unit
    }

    getSlot(elementId) {
        return this.slots.get(elementId);
    }

    getGamSlots() {
        const slotList = []
        for (const [slotID, slot] of this.slots.entries()) {
            if (slot && slot.isGamSlot()) {
                slotList.push(slot)
            }
        }
        return slotList
    }

    getSlotData(slotID) {
        return this.slotData.get(slotID);
    }

    runPostSlotInitEvents() {
        while (this.postSlotInitEvents.length) {
            const f = this.postSlotInitEvents.pop();
            if (typeof f === 'function') {
                f()
            }
        }
    }

    prepareAdServer(config) {

    }

    setupAuction(config) {
        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "Setting up auction");

        const PREBID_TIMEOUT = config.bidderTimeout;
        const self = this;

        const refreshCB = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const slotID = entry.target.id;
                    const slot = self.getSlot(slotID)
                    if (slot) {
                        const highestBids = pbjs.getHighestCpmBids(slotID);
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Highest bid for slot: ${slotID}:`, highestBids);
                        if (highestBids) {
                            slot.setWinningBid(highestBids);
                            if (slot.shouldDirectRender()) {
                                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'Direct render for slot:', slot);
                                const ret = slot.directRender();
                                if (ret) {
                                    return true
                                }
                            }
                        }
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'GAM render for slot:', slot);
                        slot.gamRefresh();
                    } else {
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "slot not found for", slotID);
                    }
                    observer.unobserve(entry.target);
                }
            });
        };

        this.refreshObserver = new IntersectionObserver(refreshCB, config.observerOptions);

        // Get all candidate divs for setting up any dynamically inserted GAM slots
        const adCollection = document.querySelectorAll(`div.${config.class}[data-ad-unit], div.${config.class}[data-adunit], div.${config.class}[data-slot]`);

        if (adCollection.length > 0) {
            adCollection.forEach(slotCandidate => {
                if (!this.getSlot(slotCandidate.id)) {
                    this.createSlot(slotCandidate.id, null, config);
                }
            }, this);
        } else {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "No adunits found - not executing auction");
        }

        googletag.cmd.push(() => {
            googletag.pubads().disableInitialLoad();
            googletag.pubads().enableSingleRequest();

            googletag.pubads().addEventListener('slotRequested', function (event) {
                const slotID = event.slot.getSlotElementId();
                const slot = self.getSlot(slotID);
                
                if (slot) {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `${slotID} requested`);
                    slot.onRequested(event);
                }
            });
    
            googletag.pubads().addEventListener('slotRenderEnded', function (event) {
                const slotID = event.slot.getSlotElementId();
                const slot = self.getSlot(slotID);
                const slotInfo = self.getSlotData(slotID);
                
                if (!slot) {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `${slotID} is not one of ours - doing nothing`);
                    return
                }
                
                if (event.isEmpty) {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'GAM slot empty:', slotID);
                    slot.onEmpty(event);
                } else {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "GAM slot rendered:", slotID);
                    slot.onRenderEnded(event);
                }

                if (!slotInfo) {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "No slot info for:", slotID);
                    return
                }
    
                if (slotInfo.refreshInterval && slotInfo.refreshInterval > 0) {
                    // TODO: setup new auction
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Setting timeout for refreshing ${slotID} in ${slotInfo.refreshInterval} seconds`);
                    setTimeout(() => { slot.refresh(); }, slotInfo.refreshInterval * 1000);
                }
            });
    
            if (config.siteConfig.uid) {
                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Setting PPID: ${config.siteConfig.uid}`);
                googletag.pubads().setPublisherProvidedId(config.siteConfig.uid);
            }
            
            self.setTargeting(config, false);

            // Send page category if present
            if (config.siteConfig.category) {
                googletag.pubads().setTargeting("category", config.siteConfig.category);
            }
            // Send site section if present
            if (config.siteConfig.section) {
                googletag.pubads().setTargeting("section", config.siteConfig.section);
            }
            // Send pagetype
            googletag.pubads().setTargeting("pagetype", getPageTypeName());

            /* if (config.siteConfig.category_iab) {
                const pps = config.siteConfig.category_iab.filter(item => item)
                if (pps.length > 0) {
                    googletag.pubads().setTargeting("category", pps);
                }
            } */
            
            googletag.enableServices();
        });

        if (config.apstagPubID) {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `APS init: ${config.apstagPubID}`);
            let apsInitObj = {
                pubID: config.apstagPubID,
                adServer: 'googletag',
                deals: true,
                videoAdServer: "DFP",
                // simplerGPT: true // https://ams.amazon.com/webpublisher/uam/docs/reference/googletag-sizemapping.html
            };
        
            if (config.enableSchain) {
                apsInitObj.schain = {
                    validation: "strict",
                    ver:"1.0",
                    complete: 1,
                    nodes: [ config.schainNode ] // TODO: make sure we can handle the case of having more than one node
                };
            }

            apstag.init(apsInitObj);    
        } else {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No APS pub id - skipping APS init`);
        }

        // TODO: we should run some postAdServeInit event
        PREBID_VARIABLE_NAME.que.push(function () {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Prebid init`);
            const pbjsConfigObj = {
                useBidCache: true,
                priceGranularity: config.priceGranularity,
                currency: {
                    adServerCurrency: "EUR",
                    defaultRates: {
                        "USD": {
                            "EUR": config.currencyMultiplier
                        }
                    }
                },
                cache: {
                    url: 'https://prebid.adnxs.com/pbc/v1/cache'
                },
                enableSendAllBids: config.enableSendAllBids,
                bidderTimeout: PREBID_TIMEOUT,
                consentManagement: {
                    gdpr: {
                        cmpApi: 'iab',
                        timeout: 8000,
                        defaultGdprScope: true
                    }
                },
                floors: {
                    currency: 'EUR',
                    default: 0.0
                }, // needs an empty object to support floors at the ad unit level
                enableTIDs: true,
                allowActivities: {
                    accessDevice: {
                        default: true,
                        rules: [
                            { allow: true }
                        ]
                    }
                },
                userSync: {
                    filterSettings: {
                        iframe: {
                            bidders: ['appnexus', 'smilewanted', 'criteo', 'pubmatic', 'ogury', 'onetag', 'openx', 'connectad'], // TODO: this should be a setting
                            filter: 'include'
                        }
                    }
                }
            };
            if (config.enableSchain) {
                pbjsConfigObj.schain = {
                    validation: "relaxed",
                    config: {
                        ver:"1.0",
                        complete: 1,
                        nodes: [ config.schainNode ] // TODO: make sure we can handle the case of having more than one node
                    }
                };
            }
            
            // Set up custom bidder schain if necessary
            for (const bidderCode in config.bidderData) {
                if (config.bidderData[bidderCode].custom_schain) {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `custom schain for ${bidderCode}:`, config.bidderData[bidderCode].custom_schain)
                    PREBID_VARIABLE_NAME.setBidderConfig({
                        "bidders": [bidderCode],
                        "config": {
                            "schain": config.bidderData[bidderCode].custom_schain
                        }
                    });
                }
            }
            if (config.enableFledge) {
                pbjsConfigObj.paapi = {
                    enabled: true,
                    gpt: {
                        autoconfig: false
                    },
                    defaultForSlots: 1,
                    bidders: ['criteo'],
                };
            }
            PREBID_VARIABLE_NAME.setConfig(pbjsConfigObj);

            pbjs.bidderSettings = {
                standard: {
                    storageAllowed: true
                }
            }
            
            // Add any bidder alias with their custom configs
            if (config.bidderAliases) {
                for (const bidderCode in config.bidderAliases) {
                    const bidderAlias = config.bidderAliases[bidderCode];
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `setting alias for ${bidderCode} to ${config.bidderAliases[bidderCode]}`)
                    if (config.bidderData[bidderAlias].custom_config) {
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Config for ${bidderAlias}: to ${config.bidderData[bidderAlias].custom_config}`)
                        PREBID_VARIABLE_NAME.aliasBidder(bidderCode, bidderAlias, config.bidderData[bidderAlias].custom_config);
                    } else {
                        PREBID_VARIABLE_NAME.aliasBidder(bidderCode, bidderAlias);
                    }
                }
            }
        });
    }

    executeAuction(config, slotList) {
        
        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Executing auction for slots:`, slotList);

        const apsUnits = [];
        const pbUnits = [];
        const self = this;
        const gamRefreshQueue = new Map();
        let requestTimeout;

        for (const slot of slotList) {
            if (slot && slot.isGamSlot() && slot.isToBeShown()) {
                googletag.cmd.push(function () {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `calling prepareSlotForAuction for ${slot.id}`);
                    const res = slot.prepareSlotForAuction();
                    if (!res) {
                        return;
                    }
                    const gamSlot = slot.getGamSlot();
                    self.gamSlots.set(slot.id, gamSlot);
                    gamRefreshQueue.set(slot.id, slot);
                    
                    const auctionRound = parseInt(slot.domElement.dataset.auctionRound || '1', 10);
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Using auction round ${auctionRound} for slot ${slot.id}`);
                    
                    const pbUnit = slot.getPBUnit(auctionRound);
                    
                    if (pbUnit) {
                        pbUnits.push(pbUnit);
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Added PB unit for ${slot.id} with ${pbUnit.bids.length} bids for tier ${auctionRound}`);
                    }
                    if (slot.apsUnit) {
                        apsUnits.push(slot.apsUnit);
                    }
                });
            }
        }

        const requestManager = {
            adserverRequestSent: false,
            aps: config.apstagPubID ? false : true, // if APS is not enabled we want to fire off requests as soon as prebid is back
            prebid: false,
            completed: (bidder) => {
                switch (bidder) {
                    case 'aps':
                        requestManager.aps = true;
                        break;
                    case 'prebid':
                        requestManager.prebid = true;
                        break;
                    default:
                        return;
                }
                // when both APS and Prebid have returned, initiate ad request
                if (requestManager.aps && requestManager.prebid) {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'all bidders back - sending GAM request and clearing timeout:', requestTimeout);
                    clearTimeout(requestTimeout);
                    sendAdserverRequest();
                    // Remove pbUnits
                    PREBID_VARIABLE_NAME.removeAdUnit(pbUnits.map(function (elem) { return elem.code }));
                }
            }
        };

        // sends adserver request
        function sendAdserverRequest() {

            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'sendAdserverRequest - self:', self);

            if (requestManager.adserverRequestSent === true) {
                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'GAM request already sent - exiting');
                return;
            }
            requestManager.adserverRequestSent = true;
    
            const refreshQueue = [];
            const removeFromQueue = [];
    
            for (const [slotID, slot] of gamRefreshQueue.entries()) {
                if (!slot) {
                    config.debug && console.warn("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "slot not in slot map:", slotID);
                    continue;
                }
                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "checking whether to refresh", slot);

                if (slot.shouldRefreshImmediately() || !self.refreshObserver) {
                    refreshQueue.push(slot);
                } else {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "setting up refresh observer for:", slot.domElement);
                    self.refreshObserver.observe(slot.domElement);
                }
                removeFromQueue.push(slotID);
            }
    
            if (refreshQueue.length > 0) {
                const immediateRefreshList = [];
                for (const s of refreshQueue) {
                    const highestBids = pbjs.getHighestCpmBids(s.id);
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'Highest bid for slot:', s, ":", highestBids);
                    if (highestBids) {
                        s.setWinningBid(highestBids);
                        if (s.shouldDirectRender()) {
                            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'Direct render for slot:', s);
                            const ret = s.directRender();
                            if (ret) {
                                continue
                            }
                            
                        }
                    }
                    immediateRefreshList.push(s.getGamSlot());
                }
                
                if (immediateRefreshList.length > 0) {
                    googletag.cmd.push(function () {
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'Immediate GAM refresh for:', immediateRefreshList);
                        googletag.pubads().refresh(immediateRefreshList);
                        // Empty the refresh queue
                        refreshQueue.length = 0;
                    });
                } else {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'No slot for immediate GAM refresh');
                }
                
            }
            
            // Remove from queue all slots that have been refreshed immediately
            for (const sid of removeFromQueue) {
                gamRefreshQueue.delete(sid);
            }

            /*
             TODO: 
             Should we remove entries from self.gamRefreshQueue as soon as they have been refreshed ?
             We should clean up the aps units and pb units as soon as bids are returned
            */
        }

        // set failsafe timeout
        requestTimeout = window.setTimeout(function () {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'sending ad server request due to timeout');
            sendAdserverRequest();
        }, config.bidderTimeout + 500);
    
        // sends bid request to APS and Prebid
        function requestHeaderBids(apsUnits, pbUnits) {
    
            // APS request
            if (config.apstagPubID) {
                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'Fetching APS bids for:', apsUnits);
                apstag.fetchBids(
                    {
                        slots: apsUnits, // send gam slot objs
                        timeout: 2000
                    },
                    function (bids) {
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'Bids back from APS:', bids);
                        googletag.cmd.push(function () {
                            apstag.setDisplayBids();
                            requestManager.completed('aps');
                        });
                    }
                );
            } else {
                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'Skipping bids for APS');
            }
    
            PREBID_VARIABLE_NAME.que.push(function () {
                PREBID_VARIABLE_NAME.addAdUnits(pbUnits);
                
                const requestObj = {
                    adUnits: pbUnits,
                    bidsBackHandler: (bids) => {
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'Bids back from prebid:', bids);
                        googletag.cmd.push(function () {
                            const slotsForGAMTargeting = [];
                            
                            for (const pbUnit of pbUnits) {
                                const slotID = pbUnit.code;
                                const slot = self.getSlot(slotID);
                                
                                if (!slot) {
                                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Slot not found for ${slotID}, skipping targeting`);
                                    continue;
                                }
                                
                                const highestBids = PREBID_VARIABLE_NAME.getHighestCpmBids(slotID);
                                
                                if (highestBids && highestBids.length > 0) {
                                    const directRenderingMultiplier = slot.priceFloors && slot.priceFloors.threshold ? 
                                                                     slot.priceFloors.threshold : 0;
                                    
                                    const bidCpm = highestBids[0].cpm;
                                    
                                    const floorPrice = slot.getPriceFloor(true);
                                    
                                    if (bidCpm < floorPrice || directRenderingMultiplier === 0) {
                                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 
                                            `Bid for ${slotID} (${bidCpm}) is too low for direct rendering (floor: ${floorPrice}) or multiplier is 0, setting GAM targeting`);
                                        slotsForGAMTargeting.push(slotID);
                                    } else {
                                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 
                                            `Bid for ${slotID} (${bidCpm}) is high enough for direct rendering, skipping GAM targeting`);
                                    }
                                } else {
                                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 
                                        `No bids for ${slotID}, not setting GAM targeting`);
                                }
                            }
                            
                            if (slotsForGAMTargeting.length > 0) {
                                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 
                                    'Setting targeting for slots:', slotsForGAMTargeting);
                                PREBID_VARIABLE_NAME.setTargetingForGPTAsync(slotsForGAMTargeting);
                            } else {
                                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 
                                    'No slots need GAM targeting');
                            }
                            
                            requestManager.completed('prebid');
                        });
                    }
                };
                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'Fetching Prebid bids for units:', pbUnits);
                if (pbUnits) {
                    requestObj.adUnits = pbUnits;
                }
                PREBID_VARIABLE_NAME.requestBids(requestObj);
            });
        }
    
        // initiate bid request
        googletag.cmd.push(function () {
            requestHeaderBids(apsUnits, pbUnits);
        });
    }

    showFallbackAdSlots(slotID, fallbackSlots) { 
        for (const fbSlotID of fallbackSlots) {

        }
    }

    /**
     * @param {*} slotList list of ids of new slots to be setup
     */
    setupNewSlots(slotList) {
        const config = window.GLOBAL_OBJECT.config;
        const gamSlots = [];
        for (const slotID of slotList) {
            let slotConfig = this.slotData.get(slotID);
            let elem = document.getElementById(slotID);
            if (!elem) {
                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No element for ${slotID} - could not set up new slot`);
                continue;
            }
            if (!slotConfig) {
                const adUnitCode = elem.dataset.adUnit;
                slotConfig = this.getSlotConfig(adUnitCode);
                slotConfig.type = elem.dataset.type;
                slotConfig.format = elem.dataset.format;
                const refreshInterval = elem.dataset.refreshInterval;
                if (refreshInterval && !isNaN(refreshInterval)) {
                    slotConfig.refreshInterval = Math.round(refreshInterval);
                }
                this.slotData.set(slotID, slotConfig);
            }
            const slot = this.createSlot(slotID, slotConfig, config);
            if (!slot) {
                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Unable to set up new slot ${slotID}`);
                continue;
            }
            if (slot.isGamSlot()) {
                gamSlots.push(slot)
            }
        }
        if (gamSlots.length > 0) {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Running new auction for ${slotList}:`, gamSlots);
            this.executeAuction(config, gamSlots)
        } else {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No auction for ${slotList} - no slot was set up`);
        }
    }

    /**
     * 
     * @param {string} slotID id of the dom element for this slot
     * @returns the created slot or null
     */
    setupSlot(slotID) {
        const config = window.GLOBAL_OBJECT.config;
        try {
            const slotConfig = this.getSlotData(slotID);
            const slot = this.createSlot(slotID, slotConfig, config);
            if (slot) {
                this.slotData.set(slotID, slotConfig);
            }
            return slot
        } catch (e) {
            console.warn("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `failed setting up ${slotID}:`, e);
        }
    }

    getDynamicID() {
        return `GLOBAL_NAME-dynamic-${++this.dynamicIDCounter}`;
    }

    getSlotEmptyFunction() {
        return (slotID) => {
            const slot = this.getSlot(slotID);
            slot.onEmpty();
        }
    }

    setTargeting(config, clear = false) {
        if (clear) {
             // Clear previous page-level targeting
            googletag.pubads().clearTargeting();
        }

        // Send page category if present
        if (config.siteConfig.category) {
            googletag.pubads().setTargeting("category", config.siteConfig.category);
        }
        // Send site section if present
        if (config.siteConfig.section) {
            googletag.pubads().setTargeting("section", config.siteConfig.section);
        }
        // Send pagetype
        googletag.pubads().setTargeting("pagetype", getPageTypeName());

        /* if (config.siteConfig.category_iab) {
            const pps = config.siteConfig.category_iab.filter(item => item)
            if (pps.length > 0) {
                googletag.pubads().setTargeting("category", pps);
            }
        } */
    }

    updatePage(config, destroySlots) {
        const self = this;

        config.siteConfig.pagetype = detectPageType(config);

        if (window._flux_config) {
            if (window._flux_config.category) {
                config.siteConfig.category = window._flux_config.category
            }
            if (window._flux_config.category) {
                config.siteConfig.category = window._flux_config.category
            }
        }

        if (destroySlots) {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Destroying GAM slots`);
            googletag.destroySlots();
        }

        googletag.cmd.push(() => {
            self.setTargeting(config, true); // update page-level targeting, clearing previous info
            googletag.pubads().updateCorrelator();
        });
    }

    listenForEvents(config) {
        const self = this;
        document.addEventListener("GLOBAL_NAME_slot_added", (ev) => {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `GLOBAL_NAME_slot_added event received:`, ev);
            try {
                const slotID = ev.detail.id;
                const domElement = document.getElementById(slotID);
                if (!domElement) {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No dom element for slot to be added #${slotID}`);
                    return
                }
                const adFormat = elem.dataset.format;
                if (!adFormat) {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No ad format for slot to be added #${slotID}`);
                    return
                }
                const adFormatData = this.formatData.get(adFormat);
                if (!adFormatData) {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No ad format data for slot to be added #${slotID}`);
                    return
                }
                /**
                 * @todo handle case of formats different from GAM
                 */
                const unitCode = isMobile() ? adFormatData.unitMob : adFormatData.unit
                if (!unitCode) {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No ad unit code for slot to be added #${slotID}`);
                    return
                }
                const slotConfig = self.getSlotConfig(unitCode);
                self.slotData.set(slotID, slotConfig);
                config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Setting up new slot: #${slotID}`);
                self.setupNewSlots([slotID]);
            } catch (e) {
                console.warn("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `problem in "slot_added" handler for event:`, ev);
            }
        })

        document.addEventListener("GLOBAL_NAME_page_update", (ev) => {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `GLOBAL_NAME_page_update event received:`, ev);
            try {
                self.updatePage(config);

                const candidates = document.querySelectorAll('div.GLOBAL_NAME-dynamic:not([data-GLOBAL_NAME-handled])');
                const slotIDs = [];
                for (const candidate of candidates) {
                    let slotID = candidate.id;
                    if (!slotID) {
                        slotID = self.getDynamicID();
                        candidate.id = slotID;
                    }
                    if (!candidate) {
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No dom element for slot to be added #${slotID}`);
                        continue
                    }
                    const adFormat = candidate.dataset.format;
                    if (!adFormat) {
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No ad format for slot to be added #${slotID}`);
                        continue
                    }
                    const adFormatData = this.formatData.get(adFormat);
                    if (!adFormatData) {
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No ad format data for slot to be added #${slotID}`);
                        continue
                    }
                    /**
                     * @todo handle case of formats different from GAM
                     */
                    const unitCode = isMobile() ? adFormatData.unitMob : adFormatData.unit
                    if (!unitCode) {
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No ad unit code for slot to be added #${slotID}`);
                        continue
                    }
                    const slotConfig = self.getSlotConfig(unitCode);
                    self.slotData.set(slotID, slotConfig);
                    slotIDs.push(slotID);
                    candidate.setAttribute('data-GLOBAL_NAME-handled', "")
                }
                if (slotIDs.length > 0) {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Setting up new slot: #${slotIDs}`);
                    self.setupNewSlots(slotIDs);
                } else {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No new dynamic slots to set up`);
                }
            } catch (e) {
                console.warn("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `problem in "slot_added" handler for event:`, e);
            }
        })

        document.addEventListener("GLOBAL_NAME_page_change", (ev) => {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `GLOBAL_NAME_page_change event received:`, ev);
            try {
                self.updatePage(config, true);

                const candidates = document.querySelectorAll('div.GLOBAL_NAME-dynamic:not([data-GLOBAL_NAME-handled])');
                const slotIDs = [];
                for (const candidate of candidates) {
                    let slotID = candidate.id;
                    if (!slotID) {
                        slotID = self.getDynamicID();
                        candidate.id = slotID;
                    }
                    if (!candidate) {
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No dom element for slot to be added #${slotID}`);
                        continue
                    }
                    const adFormat = candidate.dataset.format;
                    if (!adFormat) {
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No ad format for slot to be added #${slotID}`);
                        continue
                    }
                    const adFormatData = this.formatData.get(adFormat);
                    if (!adFormatData) {
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No ad format data for slot to be added #${slotID}`);
                        continue
                    }
                    /**
                     * @todo handle case of formats different from GAM
                     */
                    const unitCode = isMobile() ? adFormatData.unitMob : adFormatData.unit
                    if (!unitCode) {
                        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No ad unit code for slot to be added #${slotID}`);
                        continue
                    }
                    const slotConfig = self.getSlotConfig(unitCode);
                    self.slotData.set(slotID, slotConfig);
                    slotIDs.push(slotID);
                    candidate.setAttribute('data-GLOBAL_NAME-handled', "")
                }
                if (slotIDs.length > 0) {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Setting up new slot: #${slotIDs}`);
                    self.setupNewSlots(slotIDs);
                } else {
                    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `No new dynamic slots to set up`);
                }
            } catch (e) {
                console.warn("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `problem in "slot_added" handler for event:`, e);
            }
        })
    }
}