import { configObj as config } from 'CONFIG_PATH';
import { initADV } from './adv'
import {
    detectDebug,
    detectDevice,
    detectPageType,
    injectCSS,
    getElapsedTime
 } from './utils'

 import { initCMPandWait } from './cmp'
 

window.GLOBAL_OBJECT = window.GLOBAL_OBJECT || {};
window.GLOBAL_OBJECT.config = config;
window.GLOBAL_OBJECT.eventMap = new Map();

detectDevice();

window.googletag = window.googletag || {};
window.googletag.cmd = window.googletag.cmd || [];

window.PREBID_VARIABLE_NAME = window.PREBID_VARIABLE_NAME || {}
window.PREBID_VARIABLE_NAME.que = window.PREBID_VARIABLE_NAME.que || []

const pbjs = window.PREBID_VARIABLE_NAME

config.debug = detectDebug(config);

config.siteConfig = window._flux_config || window._tca_config || window.adser || config.siteConfig || {};

(() => {
    const [initial, elapsed] = getElapsedTime();
    config.siteConfig.navigationStart = config.siteConfig.navigationStart || initial;
})();

if (config.customCSS) {
    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "Setting custom css rules");
    injectCSS(config.customCSS);
}

config.siteConfig.pagetype = detectPageType(config);

/**
 * skip admanager invocation if not desired
 */
if (!('_admanager' in config.siteConfig)) {
    config.siteConfig._admanager = true;    
}

/**
 * Set up the Consent Managment Platform,
 * wait for consent and then init all adv functions
 */
initCMPandWait(config, initADV)