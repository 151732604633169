import { BaseSlot } from './base';

export class RichmediaSlot extends BaseSlot {

    type = 'richmedia'

    init(force) {
        
        const res = super.init(force);

        if (!res) {
            return
        }
        
        this.state = 'requested';

        this.onDefined();

        this.onRequested();

        return this
    }

    onDefined() {
        const cEvent = new CustomEvent("GLOBAL_NAME_slot_defined", { detail: this.getSlotDetailForEvent() });
        document.dispatchEvent(cEvent);
    }

}