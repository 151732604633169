/**
 * Load an external script
 * Allows setting attributes on the script element via the options argument
 * A callback can be provided in the callback argument
 *
 * @param {String} url
 * @param {object} attributes
 * @param {function} callback
 */
export const loadScript = (url, attributes, callback) => {
    if (typeof options === 'function') {
        callback = options;
        options = {};
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';

    if (callback) {
        if (script.readyState) { // IE
            script.onreadystatechange = function () {
                if (script.readyState === 'loaded' || script.readyState === 'complete') {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else { // Other browsers
            script.onload = function () {
                callback();
            };
        }
    }

    script.src = url;

    if (typeof attributes === 'object') {
        for (key in attributes) {
            if (attributes.hasOwnProperty(key)) {
                script.setAttribute(key, attributes[key]);
            }
        }
    }

    document.getElementsByTagName('head')[0].appendChild(script);
};

export const loadAPS = () => {
    /**
    * Find a way to load it with the standard loadscript mechanism
    */
    !function (a9, a, p, s, t, A, g) {
        if (a[a9]) return;

        function q(c, r) {
            a[a9]._Q.push([c, r])
        }
        a[a9] = {
            init: function () {
                q("i", arguments)
            },
            fetchBids: function () {
                q("f", arguments)
            },
            setDisplayBids: function () { },
            targetingKeys: function () {
                return []
            },
            _Q: []
        };
        A = p.createElement(s);
        A.async = !0;
        A.src = t;
        g = p.getElementsByTagName(s)[0];
        g.parentNode.insertBefore(A, g)
    }("apstag", window, document, "script", "//c.amazon-adsystem.com/aax2/apstag.js");
};


export const loadAdserverScripts = (config) => {
    config.adManagerScripts.map(function (s) {
        const args = [s.url]
        if ('options' in s) {
            args.push(s.options)
        }
        if ('callback' in s) {
            args.push(s.callback)
        }
        if ('preflight' in s) {
            args.push(s.preflight)
        }
        loadScript.apply(this, args)
    })
    /**
     * load APS only if we have a pub id
     */
    if (config.apstagPubID) {
        loadAPS();
    }
};