import { isMobile, injectCSS } from '../utils';
import { GamSlot } from './gam';

export class OverlaySlot extends GamSlot {
        
    type = 'overlay';
    
    containerId;
    containerElement;
    timer;
    enableCloseTimeout;
    forceCloseTimeout;
    enableCloseSeconds;
    forceCloseSeconds;
    shown;
    adUnitCode;
    frequency;
    frequencyCapped;
    overlayZIndex;
    overlayOpacity;
    backgroundRGBColor;

    constructor(slotID, slotConfig, manager, config) {
        super(slotID, slotConfig, manager, config);
        this.containerId = `${config.class}-${this.type}`;
        this.containerElement = null;
        this.immediate = true;
        this.timer = null;
        this.enableCloseTimeout = null;
        this.forceCloseTimeout = null;
        this.enableCloseSeconds = 5;
        this.forceCloseSeconds = this.debug ? 600 : 60;
        this.shown = null;
        this.adUnitCode = '';
        this.frequencyCapped = false;
        this.overlayZIndex = 9876543211;
        this.overlayOpacity = 0.85;
        this.backgroundRGBColor = '0,0,0';
    }

    init(force) {
        const slotConfig = this.slotConfig;
        
        if ('frequencyCap' in slotConfig) {
            this.frequencyCap = slotConfig.frequencyCap;
        }

        if (slotConfig.preferences && typeof slotConfig.preferences === 'object') {
            if ('enableCloseSeconds' in slotConfig.preferences) {
                this.enableCloseSeconds = slotConfig.preferences.enableCloseSeconds;
            }
            
            if ('forceCloseSeconds' in slotConfig.preferences) {
                this.forceCloseSeconds = slotConfig.preferences.forceCloseSeconds;
            }
            
            if ('overlayZIndex' in slotConfig.preferences) {
                this.overlayZIndex = slotConfig.preferences.overlayZIndex;
            }
        }

        if (!this.isToBeShown()) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'slot not to be shown:', slotConfig);
            return;
        }

        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "setting up Overlay slot for:", this.id, "- config:", slotConfig);

        this.setupOverlayContainer();

        const slot = super.init(false);

        if (!slot) {
            return slot;
        }

        // Checking Frequency Cap
        if (!this.checkFrequencyCap()) { // TODO: remove frequency cap
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "overlay frequency capped");
            if (!this.force) {
                this.frequencyCapped = true;
                this.manager.postSlotInitEvents.push(() => {
                    this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "overlay frequency capped - emitting empty event in post-init");
                    this.onEmpty();
                });
                return false;
            }
        }

        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "Overlay slot at the end of init", this);
        return this;
    }

    /**
     * Check if this slot overlay should be shown based on the frequency cap
     * 
     * @returns {boolean} true if the overlay should be shown
     */
    checkFrequencyCap() {

        if (this.debug) {
            return true;
        }
        const nowDate = new Date();
        const nowSeconds = Math.floor(nowDate.getTime() / 1000);
        const oneDayAgoSeconds = nowSeconds - 86400;
        const oneHourAgoSeconds = nowSeconds - 3600;

        this.shown = JSON.parse(localStorage.getItem(this.keyNameShown)) || [];

        const shownInfo = this.shown.reduce((acc, curr) => {
            return {
                hour: acc.hour + (curr.when > oneHourAgoSeconds ? 1 : 0),
                day: acc.day + (curr.when > oneDayAgoSeconds ? 1 : 0)
            };
        }, {
            hour: 0,
            day: 0
        });

        // If hourly frequency cap and we have surpassed our hourly frequency cap, bail
        if (this.frequencyCap.hourly && shownInfo.hour >= this.frequencyCap.hourly) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "too many overlays shown in last hour, bailing:", shownInfo.hour);
            return false;
        }
        // If daily frequency cap > 0 and we have surpassed our daily frequency cap, bail
        if (this.frequencyCap.daily && shownInfo.day >= this.frequencyCap.daily) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "too many overlays shown in last day, bailing:", shownInfo.day);
            return false;
        }
        return true
    }

    updateShown() {
        const now = new Date();
        const tstamp = Math.floor(now.getTime() / 1000);
        if (!Array.isArray(this.shown)) {
            this.shown = [];
        }
        this.shown.push({ when: tstamp });
        this.shown = this.shown.filter(item => item.when > tstamp - 86400);
        localStorage.setItem(this.keyNameShown, JSON.stringify(this.shown));
    }

    insertOverlayStyle() {
        let containerId = this.containerId;
        const css = `
        #${containerId} {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background: rgba(${this.backgroundRGBColor}, ${this.overlayOpacity});
            display: none;
            justify-content: center;
            align-items: center;
            z-index: ${this.overlayZIndex};
            opacity: 0;
            transition: opacity 0.4s ease-in-out;
        }

        #${containerId}.flx-visible {
            opacity: 1;
            display: flex;
        }

        #${containerId}-tools {
            position: absolute;
            top: 14px;
            right: 14px;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.6);
            border: 1px solid #B7B7B7;
            color: #fff;
            font-size: 18px;
            border-radius: 50%;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;
            z-index: ${this.overlayZIndex};
            font-family: sans-serif !important;
        }

        #${containerId}-tools:hover {
            background-color: rgba(0, 0, 0, 0.9);
        }

        #${containerId}-close-button {
            display: none;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            padding-top: 1px;
            font-size: 20px;
        }

        #${containerId}-tools:hover #${containerId}-close-button {
            color: rgba(255, 255, 255, 0.8);
        }

        #${containerId}-ad-area {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 90%;
            height: 100%;
            position: relative;
        }

        #${containerId}-ad-area iframe {
            max-width: none !important
        }

        #${this.id} {
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
            display: contents;
        }

        #${this.id}.GLOBAL_NAME-visible {
            opacity: 1;
            transform: translateY(20px);
        }
        `;
        injectCSS(css);
    };

    setupOverlayContainer() {
        let slot = this.slotConfig;
        const adUnitCode = isMobile() ? slot.unitMob : slot.unit;

        if (!adUnitCode) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "No ad unit for overlay - calling onEmpty");
            this.onEmpty();
            return false;
        }

        const containerId  = this.containerId;
        const adAreaId = `${containerId}-ad-area`;

        // Adding Overlay Container Element
        this.containerElement = document.createElement('div');
        this.containerElement.setAttribute('id', containerId);
        this.containerElement.innerHTML = `
            <div id="${containerId}-tools">
                <div id="${containerId}-close-button">&#x2715;</div>
                <div id="${containerId}-timer"><span id="${containerId}-remaining-seconds">${this.enableCloseSeconds}</span></div>
            </div>
            <div id="${adAreaId}"></div>
        `;
        this.insertOverlayStyle();
        document.body.appendChild(this.containerElement);

        // add event listener to close button
        document.getElementById(`${containerId}-close-button`).addEventListener("click", e => { 
            this.remove();
        });
        
        // Adding Slot Element
        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'element not in page for adslot - creating:', this.id);
        this.domElement = document.createElement('div');
        this.domElement.setAttribute('id', this.id); // set id
        this.domElement.setAttribute('class', this.slotClass);

        const adAreaElement = document.getElementById(adAreaId);
        if (adAreaElement) {
            adAreaElement.appendChild(this.domElement)
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'Ad slot element created:', this.id);
        } else {
            this.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: red", "Failed to insert ad slot element:", this.id);
            return null;
        }
    }

    refresh() {
        if (this.frequencyCapped) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "overlay frequency capped - no refresh listener", this.id);
            return;
        }

        googletag.cmd.push(() => {
            super.gamRefresh();
        });
    }

    remove() {
        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "removing overlay");
        this.enableCloseSeconds = 0;
        clearInterval(this.timer);
        clearTimeout(this.forceCloseTimeout);
        clearTimeout(this.enableCloseTimeout);
        const overlayContainer = document.getElementById(this.containerId);
        if (overlayContainer) {
            overlayContainer.remove();
        } else {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "container not found: #", this.containerId);
        }
        this.onClose(this.id);
    }

    enableClose() {
        document.getElementById(`${this.containerId}-timer`).remove();
        document.getElementById(`${this.containerId}-close-button`).style.display = 'flex';
        clearInterval(this.timer);
    }

    scaleIframe(iframe) {
        const { width, clientWidth, height, clientHeight } = iframe;
        const iframeActualWidth = width || clientWidth;
        const iframeActualHeight = height || clientHeight;
        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "Overlay iframe dimensions:", {
            width, clientWidth, height, clientHeight
        });
        const scale = Math.min(
            (window.innerWidth * 0.9) / iframeActualWidth,
            window.innerHeight / iframeActualHeight
        );
        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "Overlay scale:", scale);
        if (scale < 1) {
            // const scaledWidth = scale * iframeActualWidth;
            const scaledHeight = scale * iframeActualHeight;

            iframe.style.transformOrigin = 'center top';
            iframe.style.transform = `scale(${scale})`;
            iframe.style.width = `${width}px`;
            iframe.style.height = `${height}px`;
            iframe.parentNode.style.height = `${scaledHeight}px`;
            iframe.style.setProperty('max-width', 'none', 'important');
        }
    }

    onRenderEnded(event) {
        const slot = this.domElement;
        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "Overlay render ended:", slot);
        const iframe = slot.querySelector('iframe');
        this.scaleIframe(iframe);

        this.containerElement.classList.add("GLOBAL_NAME-visible");
        slot.classList.add("GLOBAL_NAME-visible");
        this.updateShown();

        this.forceCloseTimeout = setTimeout(() => {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `removing #${slot.id} after ${this.forceCloseSeconds} seconds`);
            this.remove();
        }, this.forceCloseSeconds * 1000);

        this.enableCloseTimeout = setTimeout(() => {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "enable closing button in timeout");
            clearInterval(this.timer);
            this.enableClose();
        }, (this.enableCloseSeconds + 1) * 1000);

        const changeRemainingTime = () => {
            if (this.enableCloseSeconds > 0) {
                document.getElementById(`${this.containerId}-remaining-seconds`).innerText = this.enableCloseSeconds;
                this.enableCloseSeconds -= 1;
            } else {
                this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `showing #${slot.id} close button`);
                this.enableClose();
                clearTimeout(this.enableCloseTimeout);
            }
        };

        this.timer = setInterval(changeRemainingTime, 1000);
    }

    onClose(slotID) {
        super.onClose(slotID);
        this.onEnd();
    }

    onEmpty(event) {
        super.onEmpty(event);
        this.onEnd();
    }
};