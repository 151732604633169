import { loadScript } from './utils'

const startTracking = () => {
    console.log("[GLOBAL_NAME - CMP] __tcfapi available");
    __tcfapi('getTCData', 2, function(tcData, success) {
        if (success) {
            window.GLOBAL_OBJECT.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: white", "getTCData success:", tcData);
            window.GLOBAL_OBJECT.cmp.vendorConsents = tcData.vendor.consents;
            window.GLOBAL_OBJECT.cmp.purposeConsents = tcData.purpose.consents;
            window.GLOBAL_OBJECT.trackingScripts.forEach( function(trackingFunction) {
                window.GLOBAL_OBJECT.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: white", "calling tracking function:", trackingFunction);
                trackingFunction();
            });
        } else {
            window.GLOBAL_OBJECT.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: white", "getTCData failure");
        }
    });
};


const loadClickio = (url, config, callback) => {
    (window.__lxG__consent__ = window.__lxG__consent__ || {}).consentCallback = function(consentState) {
        /**
         * consentState == -1 - consent still pending
         */
        console.log('[GLOBAL_NAME - CMP] consent callback - state:', consentState);
        if (consentState !== -1) {
            // consentState === null for users outside the EU
            if (consentState !== null) {
                if (window.__tcfapi) {
                    startTracking();
                    callback(config);
                } else {
                    console.log("[GLOBAL_NAME - CMP] __tcfapi not available");
                }
            } else {
                console.log("[GLOBAL_NAME - CMP] outside the EU");
                if (document.readyState === "complete" || document.readyState === "loaded") {
                    callback(config);
                } else {
                    document.addEventListener('DOMContentLoaded', () => {
                        startTracking();
                        callback(config);
                    });
                }
            }
        }
    }
    loadScript(url);
};



export const initCMPandWait = (config, initADVCallback) => {

    if (config.siteConfig.consent_expressed) {
        console.log("[GLOBAL_NAME - CMP] consent available from caller - starting");
        initADVCallback(config)
        return
    }

    /**
     * TODO: handle case where CMP is loaded from our configuration
     */
    if (config.siteConfig.cmp) {
        switch (config.siteConfig.cmp) {
            case 'clickio':
                loadClickio(config.siteConfig.cmp_params, config, initADVCallback);
                break;
            default:
                console.log("[GLOBAL_NAME] unsopported CMP:", config.siteConfig.cmp);
        }
    } else {

        const waitForTCFAPI = callbackFunction => {
            let tcfFound = false;
            let interval;
            let checkInterval = 500;
            let failTimeoutID = null;
        
            // Function to check for the presence of __tcfapi
            function checkForTCFAPI() {
                if (tcfFound) {
                    console.log("[CMP] __tcfapi already found.");
                    return;
                }
                
                if (typeof __tcfapi === 'function') {
                    console.log("[CMP] __tcfapi is now available.");
                    tcfFound = true;
                    clearInterval(interval); // Stop the interval when __tcfapi is found
                    if (failTimeoutID) {
                        clearTimeout(failTimeoutID)
                    }
                    callbackFunction(); // Execute the callback function
                }
            }
        
            // Run the check immediately
            checkForTCFAPI();
        
            if (!tcfFound) {
                // Check every 500ms if __tcfapi is not yet available
                interval = setInterval(() => {
                    console.log(`[CMP] waiting ${checkInterval} for __tcfapi`);
                    checkForTCFAPI();
                }, checkInterval);
        
                // Optional timeout to stop waiting after a certain time (e.g., 10 seconds)
                failTimeoutID = setTimeout(() => {
                    clearInterval(interval);
                    console.error("[CMP] Timed out waiting for __tcfapi to become available.");
                }, 20000);
            }  
        };
        
        const checkConsentAndListen = () => {
            __tcfapi('addEventListener', 2, (tcData) => {
                if (!tcData) {
                    console.error("[GLOBAL_NAME - CMP] tcData empty");
                    return
                }
                if (tcData.gdprApplies && (!tcData.tcString || tcData.eventStatus == "cmpuishown")) {
                    console.log("Consent has not been expressed yet.");
                } else if (tcData.eventStatus === 'tcloaded' || tcData.eventStatus === 'useractioncomplete') {
                    console.log("[GLOBAL_NAME - CMP] consent expressed - removing event listener and starting");
                    // Remove event listener
                    __tcfapi("removeEventListener", 2, (success) => {
                        if (success) {
                            // Event fired, and eventListener removed
                        }
                    }, tcData.listenerId);
                    try {
                        initADVCallback(config)
                    } catch (e) {
                        console.error("[GLOBAL_NAME - CMP] error in adv callback:", e);
                    }
                } else {
                    console.log("[GLOBAL_NAME - CMP] unkown status:", tcData);
                }
            });
        };
        
        // Start waiting for the TCF API and then check consent
        waitForTCFAPI(checkConsentAndListen);
        
    } 
};
