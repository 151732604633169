/**
 * Set the html content of a DOM element - jQuery-like:
 * If there are script nodes inside the inner html evaluate them
 *
 * @param {Element} elem
 * @param {String} innerHTML
 */
export const setInnerHTML = (elem, innerHTML) => {
    elem.innerHTML = innerHTML;
    let scripts = elem.getElementsByTagName("script");
    // If we don't clone the results then "scripts"
    // will actually update live as we insert the new
    // tags, and we'll get caught in an endless loop
    var scriptsClone = [];
    for (var i = 0; i < scripts.length; i++) {
        scriptsClone.push(scripts[i]);
    }
    for (let i = 0; i < scriptsClone.length; i++) {
        let currentScript = scriptsClone[i];
        let s = document.createElement("script");
        // Copy all the attributes from the original script
        for (let j = 0; j < currentScript.attributes.length; j++) {
            let a = currentScript.attributes[j];
            s.setAttribute(a.name, a.value);
        }
        s.appendChild(document.createTextNode(currentScript.innerHTML));
        currentScript.parentNode.replaceChild(s, currentScript);
    }
}

/**
 * Insert the provided dom element in the specified position
 * relative to the css selector
 *
 * @param {String} cssSelector
 * @param {String} position (one of before, after, append, prepend)
 * @param {Element} elem
 */
export const insertElement = (cssSelector, position, elem) => {
    var target = document.querySelector(cssSelector);

    if (!target) {
        window.GLOBAL_OBJECT.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "selector", cssSelector, "not found for inserting element", elem);
        return null;
    }

    let res;
    
    switch (position) {
        case 'before':
            res = target.parentNode.insertBefore(elem, target);
            break;
        case 'after':
            res = target.parentNode.insertBefore(elem, target.nextSibling);
            break;
        case 'append':
            res = target.appendChild(elem);
            break;
        case 'prepend':
            res = target.insertBefore(elem, target.firstChild);
            break;
        default:
            window.GLOBAL_OBJECT.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "position", position, "not valid for insertin relative to", cssSelector);
            res = null
    }
    return res
};

/*
 * Get the offset in pixel from the top of the document
 *
 * @param {Element} elem
 */
export const getOffsetTopFromDocument = (element) => {
    const rect = element.getBoundingClientRect(); // Get the element's bounding box
    const scrollTop = window.scrollY || document.documentElement.scrollTop; // Account for the current scroll
    return Math.round(rect.top + scrollTop); // Combine the bounding box top and scroll position
};


export const injectCSS = (css) => {
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
};