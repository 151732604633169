import { BaseSlot } from './base';
import { getSitename  } from '../utils';

export class AdkaoraSlot extends BaseSlot {

    containerId = 'flx-adk-container';

    shown = null;

    init(force) {
        
        const res = super.init(force);

        if (!res) {
            return
        }

        this.shown = JSON.parse(localStorage.getItem(this.keyNameShown));

        const container = document.createElement('div');
        container.id = `${this.id}-container`;
        container.innerHTML = `
        <div class="adk-slot interstitial">
            <div id="adk_interstitial0"></div>
            <div id="adk_interstitial"></div>
        </div>`;
        document.body.append(container)

        const self = this;

        window.addEventListener("adkInteractionsEvents", event => {
            if(event.detail.id.startsWith("adk_interstitial")) {
                if (
                    event.detail.isEmpty || event.detail.frequencyCap || 
                    (typeof event.detail.canBeDelivered !== 'undefined' && !event.detail.canBeDelivered)
                ) {
                    if (event.detail.frequencyCap) {
                        this.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: white", `${this.id} frequency capped`);;
                    }
                    self.onEmpty();
                    self.onEnd();
                } else if (event.detail.closed) {
                    self.onEnd();
                } else {
                    self.updateShown();
                    self.onRenderEnded(event)
                }
            }
        });

        const s = document.createElement('script');
        s.defer = true;
        s.dataset.section = getSitename();
        s.src = 'https://cdn.adkaora.space/dorvan/generic/prod/adk-init.js'; // TODO: get this from slot preferences
        document.body.appendChild(s);
        
        this.state = 'requested';

        this.onDefined();

        this.onRequested();

        return this
    }

    updateShown() {
        const now = new Date();
        const tstamp = Math.floor(now.getTime() / 1000);
        if (!Array.isArray(this.shown)) {
            this.shown = [];
        }
        
        this.shown.push({
            when:  tstamp
        });

        // Keep information only for last 24 hours
        this.shown = this.shown.filter(item => item.when > tstamp - 86400);
        
        localStorage.setItem(this.keyNameShown, JSON.stringify(this.shown));
    }

    checkFrequencyCap() {
        const now = new Date();
        const tstamp = Math.floor(now.getTime() / 1000);

        if (!Array.isArray(this.shown)) {
            this.shown = [];
        }

        const shownByPeriod = {
            lastHour: 0,
            lastDay: 0
        }

        for (const item of this.shown) {
            if (item.when > tstamp - 86400) {
                shownByPeriod.lastDay += 1;
                if (item.when > tstamp - 3600) {
                    shownByPeriod.lastHour += 1;
                }
            }
        }
        
        if (this.frequencyCap.hourly && shownByPeriod.lastHour >= this.frequencyCap.hourly) {
            this.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: white", "not showing adkaora: too many shown in the last 60 minutes:", shownByPeriod.lastHour);
            return false;
        }
        if (this.frequencyCap.lastDay && shownByPeriod.lastDay >= this.frequencyCap.daily) {
            this.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: white", "not showing adkaora: too many shown in the last 24 hours:", shownByPeriod.lastDay);
            return false;
        }
        return true
    }

}