import { getContentWidth, injectCSS } from '../utils';
import { GamSlot } from './gam';

export class SkinDskSlot extends GamSlot {
    
    contentWidth;
    
    constructor(slotID, slotConfig, manager, config) {
        super(slotID, slotConfig, manager, config);
    }

    getMeasuredContentWidth() {
        const contentSelector = this.slotConfig.preferences?.contentSelector || 
        '.main-content, #content, main, article, .content-wrapper';
    
        const mainContent = document.querySelector(contentSelector);
        
        if (mainContent) {
            return mainContent.getBoundingClientRect().width;
        }
        
        const configuredWidth = getContentWidth();
        const scaleFactor = window.devicePixelRatio || 1;
        
        return configuredWidth * scaleFactor;
    }

    skinFallback() {
        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'skin fallback');
        const fakeSkinUnit = this.slotConfig.preferences.fallback_unit;
        if (!fakeSkinUnit) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'no fallback unit - not showing skin fallback');
            return;
        }
        
        const contentWidth = this.getMeasuredContentWidth();
        const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        
        const availableWidth = (screenWidth - contentWidth - 20) / 2;
        
        if (availableWidth < 0) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'not enough space for skin fallback');
            return;
        }
        const compatibleWidths = [300, 160, 120].filter(w => w < availableWidth);
        const distanceFromTop = Math.round(window.innerHeight / 4);
        const width = compatibleWidths.length > 0 ? compatibleWidths[0] : 0;
        if (width == 0) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'not enough space for skin fallback');
            return;
        }
        
        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 
            `skin fallback measurements - screen width: ${screenWidth}px, content width: ${contentWidth}px, available: ${availableWidth}px, width: ${width}px`);
        
        const fakeSkinLeftId =  `${this.id}-sx`;
        const fakeSkinRightId = `${this.id}-dx`;
        
        const size = [width, 600];
        const margin = Math.round((contentWidth / 2) + width + 10);
        
        const css = `
        div[id^="${fakeSkinLeftId}"] {
            position: fixed;
            top: ${distanceFromTop}px;
            left: 50%;
            margin-left: -${margin}px;
            text-align: right;
            z-index: 1;
            width: ${width}px;
            overflow: hidden;
        }
        div[id^="${fakeSkinRightId}"] {
            position: fixed;
            top: ${distanceFromTop}px;
            right: 50%;
            margin-right: -${margin}px;
            z-index: 1;
            width: ${width}px;
            overflow: hidden;
        }`;
        injectCSS(css);
        
        for (const fallbackID of [fakeSkinLeftId, fakeSkinRightId]) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `setting up ${fallbackID} with unit ${fakeSkinUnit}`);
            const div = document.createElement('div');
            document.body.appendChild(div);
            div.id = fallbackID;
            div.dataset.adUnit = fakeSkinUnit;
            div.dataset.format = 'fake-skin';
            div.dataset.type = 'gam';
            div.dataset.refreshInterval = 30;
            div.dataset.sizes = JSON.stringify([size]);
            div.dataset.pbSizes = JSON.stringify([size]);
            div.dataset.apsSizes = JSON.stringify([size]);
        }
        this.manager.setupNewSlots([fakeSkinLeftId, fakeSkinRightId]);
    }

    onEmpty() {
        super.onEmpty();
        this.skinFallback();
    }
};