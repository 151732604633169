import { GamSlot } from './gam';

export class SkinMobSlot extends GamSlot {

    maxHeight = 100; // TODO: make this a slot preference

    constructor(slotID, slotConfig, manager, config) {
        super(slotID, slotConfig, manager, config);
        this.gamSlot = null;
        this.dimensions = [];
        this.prebidSizes = [];
        this.floorInfo = null;
        this.immediate = true;
        this.directRenderApplied = false;
    }

    init(force) {
        const res = super.init(true);

        if (!res) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "problem with super class init for mobile skin");
            return res
        }

        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "mobile skin init:", this.slotConfig);

        const parent = this.domElement.parentNode;
        this.domElement.dataset.type = this.slotConfig.type; // TODO: is this redundant ?
        
        parent.style.display = 'flex';
        parent.style.justifyContent = 'center';
        parent.style.alignItems = 'center';
        return this
    }

    scaleBanner(domElement) {
        let scaled = false;
        let maxHeight = this.maxHeight;
        let slotWidth = domElement.width;
        if (!domElement.width || domElement.width == '1') {
            slotWidth = parseInt(domElement.style.width.replace('px', ''));
        }
        if (isNaN(slotWidth)) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "width of mobile iframe isNaN:", domElement.width, domElement);
            return scaled;
        }
        let slotHeight = domElement.height;
        if (!domElement.height || domElement.height == '1') {
            slotHeight = parseInt(domElement.style.height.replace('px', ''));
        }
        
        const parentNode = domElement.parentNode;
        
        const scale = Math.min(
            window.innerWidth / slotWidth,
            maxHeight / slotHeight
        );
        if (scale < 1) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "resizing mobile skin by a factor of:", scale);

            if (this.directRenderApplied) {
                domElement.style.transformOrigin = 'center left';
                parentNode.style.height = "100%";
            } else {
                domElement.style.transformOrigin = 'top left';
                parentNode.style.height = slotHeight * scale + "px";
            }

            domElement.style.transform = `scale(${scale}) translateX(-50%)`;
            domElement.style.left = '50%';
            domElement.style.position = 'absolute';
            parentNode.style.width = slotWidth * scale + "px";
            domElement.style.width = slotWidth + "px";
            domElement.style.height = slotHeight + "px";
            domElement.style.maxWidth = 'none';
            parentNode.style.overflow = 'hidden';
            scaled = true;
        }
        return scaled;
    }

    directRender() {
        const result = super.directRender();
        if (result) {
            this.directRenderApplied = true;
        }
        return result;
    }

    onRenderEnded(event) {
        super.onRenderEnded(event);
        
        const iframe = this.domElement.querySelector('iframe');
        
        // Scale the banner to fit available screen size
        if (iframe) {
            this.scaleBanner(iframe);
            if ('ResizeObserver' in window ) {
                const resizeObserver = new ResizeObserver((entries, observer) => {
                    const entry = entries[0];
                    this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "mobile skin resized:", entry);
                    const scaled = this.scaleBanner(entry.target);
                    if (scaled) {
                        observer.unobserve(entry.target);
                    }
                });
                resizeObserver.observe(iframe);
            }
        }
    }
}