export const detectDebug = config => {
    let debug = config.debug;
    try {
        let url = new URL(window.location.href);
        if (url.searchParams.has(`${config.class}-verbose`)) {
            debug = true;
        }
    } catch (error) { }
    return debug;
};
