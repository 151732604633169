import { getOffsetTopFromDocument } from './dom'
import { getDevice } from './device';
import { getPageTypeName } from './pagetype';
import { getElapsedTime } from './timing';
import { getUserType } from './user';


/**
 * UUID generator
 */
const uuidGenerator = () => {
    if (window.crypto && window.crypto.randomUUID) {
        return crypto.randomUUID();
    }

    const d = new Date().getTime();//Timestamp
    const d2 = (performance && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16;//random number between 0 and 16
        if(d > 0) { // Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d/16);
        } else { // Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export const initTracking = config => {

    let [pageOpenTime, engagedTime] = getElapsedTime();

    let startTime = null;

    const secondsSincePageOpen = () => {
        const [initial, elapsed] = getElapsedTime();
        // Calculate the time passed
        return elapsed;
    };

    startTime = Math.floor(Date.now() / 1000);

    const track = {
        obj: {
            site: config.sitename,
            adv_start: secondsSincePageOpen(),
            path: document.location.pathname,
            pid: uuidGenerator(),
            device: getDevice(),
            section: config.siteConfig.section,
            page_type: getPageTypeName(),
            user_type: getUserType(),
            paywalled: config.siteConfig.paywalled,
            category: config.siteConfig.category,
            navigation_start: config.siteConfig.navigationStart,
            impressions: {},
            first: true
        },
        changed: true,
        beaconUrl: config.beaconUrl
    };

    window.GLOBAL_OBJECT.trackObj = track.obj;

    /**
     * Send tracking info
     */
    const sendBeacon = () => {
        if (!navigator.sendBeacon) {
            return;
        }
        if (!track.changed) {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "beacon already sent - not re-sending");
            return;
        }

        // Send only once
        track.changed = false;

        track.obj.timespent = engagedTime;

        const blob= new Blob([JSON.stringify(track.obj)], {type : 'application/json; charset=UTF-8'});

        // Send tracking info
        navigator.sendBeacon(track.beaconUrl, blob);

        track.obj.first = false;
    }

    let uid = localStorage.getItem("GLOBAL_NAME-uid");
    if (!uid) {
        uid = uuidGenerator();
        localStorage.setItem("GLOBAL_NAME-uid", uid);
    }
    track.obj.uid = uid;

    // Send the first beacon
    sendBeacon();

    window.addEventListener('beforeunload', function() {
        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "sending beacon - beforeunload event");
        if (startTime) {
            engagedTime += Math.floor(Date.now() / 1000) - startTime;
            startTime = null;
        }
        sendBeacon();
    }, false);

    document.addEventListener("visibilitychange", function() {
        if(document.visibilityState === 'hidden') {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "sending beacon - visibility has become hidden");
            if (startTime) {
                engagedTime += Math.floor(Date.now() / 1000) - startTime;
                startTime = null;
            }
            sendBeacon();
        } else {
            startTime = Math.floor(Date.now() / 1000);
        }
    });

    document.addEventListener("GLOBAL_NAME_slot_defined", function(event) {
        track.changed = true;
        
        track.obj.impressions[event.detail.id] = {
            slot_id: event.detail.id,
            format: event.detail.slotConfig.format,
            type: event.detail.slotConfig.type,
            state: 'defined',
            empty: null,
            time_requested: null,
            time_rendered: null,
            offset_top: getOffsetTopFromDocument(event.detail.slot)
        };
    });

    document.addEventListener("GLOBAL_NAME_slot_requested", function(event) {
        track.changed = true;

        const slotID = event.detail.id;

        const impression = track.obj.impressions[slotID]

        if (impression) {
            impression.state = 'requested';
            impression.time_requested = secondsSincePageOpen();
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE",`impression updated:`, impression)
        } else {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE",`slot ${slotID} not found in track object`)
        }
    });

    document.addEventListener("GLOBAL_NAME_slot_rendered", function(event) {
        track.changed = true;

        const slotID = event.detail.id;

        const impression = track.obj.impressions[slotID]

        if (impression) {
            impression.empty = false;
            impression.state = 'rendered';
            impression.time_rendered = secondsSincePageOpen();
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE",`impression updated:`, impression)
        } else {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE",`slot ${slotID} not found in track object`)
        }
    });

    document.addEventListener("GLOBAL_NAME_slot_empty", function(event) {
        track.changed = true;
        
        const slotID = event.detail.id;

        const impression = track.obj.impressions[slotID]

        if (impression) {
            impression.state = 'rendered';
            impression.empty = true;
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE",`impression updated:`, impression)
        } else {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE",`slot ${slotID} not found in track object`)
        }
    });

    
};