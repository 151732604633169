import { insertElement, getPageTypeName, getDevice, isMobile, setInnerHTML, isSubscriber } from '../utils';

export class BaseSlot {
    
    id = null;

    type = 'base';

    format = null;

    debug = null;

    keyNameShown = null;

    slotConfig = null;

    slotClass = null;

    domElement = null;

    manager = null;

    state = null;

    needsRefresh = false;

    constructor(slotID, slotConfig, manager, config) {
        this.id = slotID;
        this.slotConfig = slotConfig;
        this.type = slotConfig.type;
        this.format = slotConfig.format;
        this.slotClass = config.class;
        this.keyNameShown = `_${config.class}_${slotID}_shown`;
        this.debug = config.debug;
        this.manager = manager;
        this.priceFloors = this.slotConfig.priceFloors ? this.slotConfig.priceFloors : config.priceFloors;
    }

    init() {
        
        const slot = this.slotConfig;
        
        if ('frequencyCap' in slot) {
            this.frequencyCap = slot.frequencyCap;
        }

        if (!this.isToBeShown()) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'slot not to be shown:', slot);
            return null;
        }

        this.domElement = document.getElementById(this.id);
        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Init of ${this.id} - DOM element:`, this.domElement);

        // If there's no DOM element in page and the slot is to be forced, create the dom element
        if (!this.domElement && slot.force) {
            let selector = 'body';
            let position = 'append';
            let slot = this.slotConfig;
            
            if ('insert' in slot) {
                selector = slot.insert.selector;
                position = slot.insert.position;
            }
            
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'element not in page for adslot - creating:', this.id);
            this.domElement = document.createElement('div');
            this.domElement.setAttribute('id', this.id); // set id
            this.domElement.setAttribute('class', this.slotClass);
        
            const elem = insertElement(selector, position, this.domElement);
            if (!elem) {
                this.debug && console.warn("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `could not insert ${this.domElement} for ${this.id}`);
                return null;
            }
        } 
        
        // If still no DOM element in page slot is not to be shown
        if (!this.domElement) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `slot ${this.id} not in page and not forced - skipping`);
            return null
        }

        if (slot.innerHTML) {
            this.setInnerHTML()
        }

        this.state = 'defined';

        this.onDefined();

        return this

    }

    setInnerHTML() {
        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `setting innerHTML for slot ${this.id}:`, this.slotConfig.innerHTML);
        setInnerHTML(this.domElement, this.slotConfig.innerHTML)
    }

    getSlotDetailForEvent(event) {
        const eventDetail = {
            id: this.id,
            slot: this.domElement,
            slotConfig: this.slotConfig,
            type: this.type,
            state: this.state
        }
        if (this.isGamSlot()) {
            eventDetail.gamEvent = event;
        }
        return eventDetail
    }

    isGamSlot() {
        return false;
    }

    getPriceFloor(forRender=false) {
        if (this.priceFloors) {
            let floor = isMobile() ? this.priceFloors.mobile : this.priceFloors.desktop;
            if (forRender) {
                floor *= this.priceFloors.threshold
            }
            return floor
        }
        return 0.0
    }

    isToBeShown() {
        const pagetype = getPageTypeName();
        // Check if we must show on this device

        const device = getDevice();

        if (!this.slotConfig.devices.includes(device)) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `not showing slot ${this.id} - wrong device: ${device}`);
            return false;
        }
        // Check if we must show on this pagetype
        if ('pageType' in this.slotConfig && this.slotConfig.pageType.indexOf(pagetype) == -1) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `not showing slot ${this.id} - wrong pagetype: ${pagetype}`);
            return false;
        }

        if (isSubscriber() && !this.slotConfig.showAll) {
            return false;
        }

        return true;
    }

    /**
     * Insert an advertising slot in page. If needed and if the slot must be forced, create the DOM element to host it
     * according to the slot configuration
    */
    insert() {
        const adSlotId = this.id;
        try {
            const slot = this.slotConfig;
            const force = slot.force;

            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'loading adslot:', this.id, "- type:", this.type);
            if (!this.isToBeShown()) {
                this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'slot not to be shown:', slot);
                return;
            }
            this.init(force);

            let elem = this.domElement;
            

            if (elem) {
                // TODO: move data unit attributes to GamSlot child class 
                if (elem.hasAttribute("data-adunit") || elem.hasAttribute("data-slot")) {
                    this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'GAM slot - not setting innerHTML:', adSlotId);
                    if (!elem.classList.contains(this.slotClass)) {
                        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'Adding class to gam slot:', elem);
                        elem.classList.add(this.slotClass);
                    }
                } else if (slot.innerHTML) {
                    setInnerHTML(elem, slot.innerHTML)
                } else {
                    const adUnitCode = isMobile() ? slot.unitMob : slot.unit
                    if (adUnitCode) {
                        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'adding ad unit", adUnitCode, "to:', adSlotId);
                        elem.dataset.adunit = adUnitCode;
                        elem.classList.add(this.slotClass);
                    } else {
                        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'no ad unit, no innerHTML for elem:', adSlotId);
                    }
                }
            } else {
                console.warn("[GLOBAL_NAME] slot not in page and not forced - id:", adSlotId);
            }
        } catch (error) {
            console.warn("[GLOBAL_NAME] error setting up slot", adSlotId, "-", error);
        }
    }

    postInit() { }

    /**
     * Any action that must be taken before the slot is rendered in page
     */
    preRender() { }

    /**
     * Any action that must be taken when the slot is empty
     * 
     * @todo define the correct structure aof the events
     */
    onEmpty(event) {
        const eventDetail = this.getSlotDetailForEvent(event);

        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `dispatching on empty events`, eventDetail);
        let cEvent = new CustomEvent(`GLOBAL_NAME_slot_empty`, {
            detail: eventDetail
        });
        document.dispatchEvent(cEvent);

        if (this.domElement) {
            const cEventByType = new CustomEvent("GLOBAL_NAME_" + this.type + "_empty", {
                detail: eventDetail 
           });
           this.domElement.dispatchEvent(cEventByType);
        }
        
        let onEmptySlots = [];
        if (this.slotConfig.onEmptySlots && Array.isArray(this.slotConfig.onEmptySlots)) {
            onEmptySlots = this.slotConfig.onEmptySlots
        } else if (this.domElement.dataset.fallbackSlot) {
            onEmptySlots.push(this.domElement.dataset.fallbackSlot)
        }
        if (onEmptySlots.length > 0) {
            try {
                for (const fbSlotID of onEmptySlots) {
                    const newElement = document.createElement('div');
                    newElement.id = fbSlotID;
                    this.domElement.insertAdjacentElement('afterend', newElement);
                }
                this.manager.setupNewSlots(onEmptySlots);
                // remove previous slot
                this.domElement.remove();
            } catch (e) {
                console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `error setting up fallback slots for ${this.id}:`, e);
            }
        }
    }

    setupFallbackSlots(slotList) {
        const fallbackSlots = []
        for (const slotID of slotList) {
            let newElement = document.getElementById(slotID)
            
            if (!newElement) {
                newElement = document.createElement('div');
                newElement.id = slotID;
                // Insert the new element after the reference element
                if (this.domElement) {
                    this.domElement.insertAdjacentElement('afterend', newElement);
                } else {
                    let selector = 'body';
                    let position = 'append';
                    const slotConfig = this.slotConfig;
                    if ('insert' in slotConfig) {
                        selector = slotConfig.insert.selector;
                        position = slotConfig.insert.position;
                    }
                    const elem = insertElement(selector, position, newElement);
                }
            }
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `setting up ${slotID} fallback slot`);
            const slot = this.manager.setupSlot(slotID)
            if (slot) {
                fallbackSlots.push(slot);
            } else {
                this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `failed setting up ${slotID} fallback slot`);
            }
        }
        return fallbackSlots;
    }

    onDefined() {
        const cEvent = new CustomEvent("GLOBAL_NAME_slot_defined", { detail: this.getSlotDetailForEvent() });
        document.dispatchEvent(cEvent);
    }

    onRequested() {
        /**
         * Fire off an event noting when the slot has been defined in page
         */
        const eventDetail = this.getSlotDetailForEvent();

        const cEvent = new CustomEvent("GLOBAL_NAME_slot_requested", { detail: this.getSlotDetailForEvent() });
        document.dispatchEvent(cEvent);
    }

    onRenderEnded(event) {
        this.state = 'rendered';
        
        const eventDetail = this.getSlotDetailForEvent(event);
        
        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `dispatching ${this.type} rendered event`);
        document.dispatchEvent(new CustomEvent(`GLOBAL_NAME_slot_empty`, {
            detail: eventDetail
        }));

        document.dispatchEvent(new CustomEvent(`GLOBAL_NAME_${this.type}_rendered`, {
            detail: eventDetail
        }));
    }

    onEnd() {
        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `dispatching ${this.type} end event`);
        document.dispatchEvent(new CustomEvent(`GLOBAL_NAME_${this.type}_end`, {
            detail: this.getSlotDetailForEvent()
        }));
        // if (this.onCloseAdSlots) {
        //     this.manager.setupFallbackAdSlots(this.id, this.onCloseAdSlots, false);
        // }
    }

    onClose() {
        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `dispatching ${this.type} end event`);
        document.dispatchEvent(new CustomEvent(`GLOBAL_NAME_${this.type}_end`, {
            detail: this.getSlotDetailForEvent()
        }));

        if (this.slotConfig.onCloseAdSlots && Array.isArray(this.slotConfig.onCloseAdSlots)) {
            try {
                for (const fbSlotID of this.slotConfig.onCloseAdSlots) {
                    const newElement = document.createElement('div');
                    newElement.id = fbSlotID;
                    this.domElement.insertAdjacentElement('afterend', newElement);
                    this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `dispatching ${this.type} end event`);
                }
                this.manager.setupNewSlots(this.slotConfig.onCloseAdSlots);
            } catch (e) {
                console.warn("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `error setting up on-close slots for ${this.id}:`, e);
            }
            
        }
    };

    refresh() {
        
    }

    prepareSlotForAuction() {}
}