import { isMobile, injectCSS } from '../utils';
import { GamSlot } from './gam';

export class StickySlot extends GamSlot {
    type = 'sticky';
    
    timeoutId;
    closeButtonDelay = 0;
    autoCloseTimeout = 0;
    margin = { top: 0, right: 0, bottom: 0, left: 0 };
    horizontalPosition = 'left';
    verticalPosition = 'bottom';
    backgroundOpacity = 0;
    containerElement;
    maxHeight;

    constructor(slotID, slotConfig, manager, config) {
        super(slotID, slotConfig, manager, config);

        if (slotConfig.preferences) {
            this.closeButtonDelay = slotConfig.preferences.closeButtonDelay;
            this.margin = slotConfig.preferences.margin;
            this.autoCloseTimeout = slotConfig.preferences.autoCloseTimeout;
            this.backgroundOpacity = slotConfig.preferences.backgroundOpacity;
            this.verticalPosition = slotConfig.preferences.verticalPosition;
            this.horizontalPosition = slotConfig.preferences.horizontalPosition;
        }
        this.maxHeight = isMobile() ? 100 : 250;
    }

    init(force) {

        const slotID = this.id;
        
        // Create ad container
        const adContainer = document.createElement('div');
        adContainer.id = `${slotID}-container`;
        adContainer.className = 'ad-container';
        adContainer.innerHTML = `
            <div class="flx-sticky-ad-content">
                <button type="button" class="flx-sticky-close-button">X</button>
                <div id="${slotID}"></div>
            </div>
        `;
        
        this.containerElement = adContainer;

        // Apply styles
        const style = `
            div#${adContainer.id} {
                position: fixed;
                ${this.getPositionStyles()};
                background-color: rgba(0, 0, 0, ${this.backgroundOpacity});
                z-index: 1000;
                display: none;
                justify-content: center;
                align-items: center;
            }
            div.flx-sticky-ad-content {
                width: auto;
                text-align: center;
                position: relative;
            }
            div.flx-sticky-close-button {
                display: none;
                position: absolute;
                top: 5px;
                right: 5px;
                background: rgba(0, 0, 0, 0.5);
                color: white;
                border: none;
                border-radius: 50%;
                cursor: pointer;
                width: 14px;
                height: 14px;
                font-size: 6px;
                padding-left: 5px;
            }
        `;
        
        injectCSS(style);

        document.body.appendChild(adContainer);

        const res = super.init(true);

        this.closeButton = adContainer.querySelector('button.flx-sticky-close-button')
        
        this.closeButton.addEventListener("click", () => this.remove());

        return this

    }

    remove() {
        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", `Removing sticky #${this.id}`);
        clearTimeout(this.timeoutId);
        this.onClose();
        this.containerElement.remove();
    }

    getPositionStyles() {
        const cssRules = [];
        const transform = [];

        if (this.horizontalPosition === 'center') {
            cssRules.push(`left: 50%;`);
            transform.push(`translateX(-50%)`);
        } else {
            cssRules.push(this.horizontalPosition === 'left' ? `left: ${this.margin.left || 0}px;` : `right: ${this.margin.right || 0}px;`);
        }

        if (this.verticalPosition === 'center') {
            cssRules.push(`top: 50%;`);
            transform.push(`translateY(-50%)`);
        } else {
            cssRules.push(this.verticalPosition === 'top' ? `top: ${this.margin.top || 0}px;` : `bottom: ${this.margin.bottom || 0}px;`);
        }

        if (this.horizontalPosition === 'center' && this.verticalPosition === 'center' && this.backgroundOpacity > 0) {
            cssRules.push(`width: 100%; height: 100%;`);
        }

        if (transform.length > 0) {
            cssRules.push(` transform: ${transform.join(' ')};`);
        }
        const css = cssRules.join("\n");

        return css;
    }

    scaleBanner(domElement) {
        let slotWidth = domElement.width;
        let slotHeight = domElement.height;
        
        if (!domElement.width || domElement.width == '1') {
            slotWidth = parseInt(domElement.style.width.replace('px', ''));
        }
        if (isNaN(slotWidth)) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "width of mobile iframe isNaN:", domElement.width, domElement);
            return false;
        }
        if (!domElement.height || domElement.height == '1') {
            slotHeight = parseInt(domElement.style.height.replace('px', ''));
        }
        
        let scale = Math.min(window.innerWidth / slotWidth, this.maxHeight / slotHeight);

        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "stickyAd scale:", scale);

        if (scale < 1) {
            this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "resizing sticky banner by a factor of:", scale);
            domElement.style.transformOrigin = `${this.horizontalPosition} ${this.verticalPosition}`;
            domElement.style.transform = `scale(${scale})`;
            domElement.style.maxWidth = 'none';
            domElement.parentNode.style.width = slotWidth + "px";
            domElement.parentNode.style.height = slotHeight * scale + "px";
            domElement.parentNode.style.overflow = 'hidden';
            return true;
        }

        return false;
    }

    onRenderEnded(event) {

        this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "stickyAd render ended:", event.size);

        const iframe = this.domElement.querySelector('iframe');
        if (iframe) {
            this.scaleBanner(iframe);
            if ('ResizeObserver' in window) {
                const resizeObserver = new ResizeObserver((entries, observer) => {
                    const entry = entries[0];
                    this.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "sticky ad resize observed:", entry);
                    const scaled = this.scaleBanner(entry.target);
                    if (scaled) {
                        observer.unobserve(entry.target);
                    }
                });
                resizeObserver.observe(iframe);
            }
        }

        this.containerElement.style.display = 'flex';

        setTimeout(() => {
            this.closeButton.style.display = 'block';
        }, this.closeButtonDelay * 1000);

        if (this.autoCloseTimeout && !isNaN(this.autoCloseTimeout)) {
            this.timeoutId = setTimeout(() => this.remove(), this.autoCloseTimeout * 1000);
        }
        
    }

    onClose(slotID) {
        super.onClose(slotID);
        this.onEnd();
    }
};