import { BaseSlot } from './base';
import { RichmediaSlot } from './richmedia';
import { GamSlot } from './gam';
import { OverlaySlot } from './overlay';
import { AdkaoraSlot } from './adkaora';
import { SkinMobSlot } from './skinmob';
import { SkinDskSlot } from './skindsk';
import { StickySlot } from './sticky';
import { VipSlot } from './vip';
import { IntextToVipSlot } from './intexttovip'

// Register all available slot types
// TODO: find a way to dynamically register these
const slotTypes = {
    'base':        BaseSlot,
    'richmedia':   RichmediaSlot,
    'gam':         GamSlot,
    'overlay':     OverlaySlot,
    'adkaora':     AdkaoraSlot,
    'skinmob':     SkinMobSlot,
    'skin':        SkinDskSlot,
    'sticky':      StickySlot,
    'vip':         VipSlot,
    'intexttovip': IntextToVipSlot
};

export { slotTypes as default };