import { getDevice, isMobile } from './device'

export const getIntextConfig = (config) => {
    return config.intextConfig[getDevice()]
};


const getIntextSlotConfig = (adUnitCode) => {
    const slotConfig = {
        "devices": [getDevice()],
        "force": false,
        "type": "gam ",
        "frequencyCap": {
            "hourly": 0,
            "daily": 0
        },
        "unitMob": isMobile() ? adUnitCode : null,
        "unit": isMobile() ? null : adUnitCode
    };
    return slotConfig;
}



const generateIntextDomElement = (elem, elementClass, adUnitCode) => {
    elem.setAttribute("data-adunit", adUnitCode);
    elem.classList.add(elementClass, `${elementClass}-intext`, `${elementClass}-300x250`);
    elem.style.display = 'block';
    // This might not be needed
    // window.GLOBAL_OBJECT.config.slotMap[elem.id] = {
    //     "devices": [getDevice()],
    //     "force": false,
    //     "type": "normal",
    //     "frequencyCap": {
    //         "hourly": 0,
    //         "daily": 0
    //     },
    //     "unitMob": isMobile() ? adUnitCode : null,
    //     "unit": isMobile() ? null : adUnitCode
    // };
    return elem;
}

/**
 * 
 * @param {int} index 
 * @param {*} defaultIntextAd 
 * 
 * @todo move this to the IntextSlot component
 */
const generateIntextAdElement = (index, intextConfig, elementClass) => {
    const containerElement = document.createElement('div')
    const idPrefix = `div-${elementClass}-intext`
    const defaultIntextAd = intextConfig.defaultAdUnit;
    containerElement.id = `${idPrefix}-container-${index}`;
    containerElement.setAttribute(
        "style",
        "min-width:300px;min-height:250px;background-color:#f2f2f2;margin: 8px auto; text-align: center; display: block; clear: both;"
    );

    const adElement = document.createElement('div');
    adElement.id = `${idPrefix}-gam-${index}`;
    
    const adList  = intextConfig.slots
    if (index < adList.length) {
        const elemToInsert = adList[index];
        window.GLOBAL_OBJECT.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: white", 'inserting intext element:', elemToInsert);
        if (elemToInsert.adslot) {
            adElement.id = elemToInsert.adslot;
        } else if (elemToInsert.ad_unit) {
            adElement = generateIntextDomElement(adElement, elementClass, elemToInsert.ad_unit);
        }
    } else {
        window.GLOBAL_OBJECT.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: white", 'inserting default intext unit:', defaultIntextAd);
        adElement = generateIntextDomElement(adElement, elementClass, defaultIntextAd);
    }
    window.GLOBAL_OBJECT.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: white", 'intext ad element:', adElement, "- container:", containerElement);
    containerElement.appendChild(adElement);
    return containerElement;
};

const isValidTag = (tag) => {
    const regex = /^(div|p)$/i;
    return regex.test(tag);
};



/**
 * Insert an element for an intext slot
 * 
 * @param {Element} intextElement 
 * @param {String} intextSelector 
 * @param {Object} config 
 * 
 * @todo move this to the IntextSlot component
 */
export const insertIntext = (intextElement, intextSelector, config) => {
    const intextConfig = getIntextConfig(config)
    const distances = intextConfig.distances;
    const ele_type = intextConfig.elementType;
    if (isValidTag(ele_type)) {
        if (intextElement) {
            let elementChildren = document.querySelectorAll(`${intextSelector} > ${intextConfig.elementType}`);
            if (!elementChildren || elementChildren.length == 0) {
                elementChildren = document.querySelectorAll(`${intextSelector} ${intextConfig.elementType}`);
            }
            if (!elementChildren || elementChildren.length == 0) {
                config.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: white", 'no place for intext adv');
                return
            }
            const elementChildrenArray = Array.from(elementChildren);
            config.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: white", 'intext container children:', elementChildren);
            const finalPlacement = [];
            const articleBottom = intextElement.offsetTop + intextElement.offsetHeight;

            elementChildrenArray.forEach((elem) => {
                const elementBottom = elem.offsetTop + elem.offsetHeight;
                const bottomOffset = elementBottom - intextElement.offsetTop;

                if (articleBottom - elementBottom < distances.bottom) {
                    config.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: white", 'no more space for intext');
                    return;
                }
                if (finalPlacement.length === 0) { // first element
                    if (bottomOffset > distances.first) {
                        const elementContent = document.createElement(ele_type);
                        elementContent.classList.add(`ads-element-${1}`);
                        finalPlacement.push({
                            target: elem,
                            content: generateIntextAdElement(finalPlacement.length, intextConfig, config.class),
                            bottomOffset: bottomOffset,
                        });
                    }
                } else {
                    const previousAd = finalPlacement[finalPlacement.length - 1];
                    if (bottomOffset - previousAd.bottomOffset > distances.next) {
                        finalPlacement.push({
                            target: elem,
                            content: generateIntextAdElement(finalPlacement.length, intextConfig, config.class),
                            bottomOffset: bottomOffset,
                        });
                    }
                }
            });
            
            //  Inject ads into DOM
            for (const item of finalPlacement) {
                item.target.parentNode.insertBefore(item.content, item.target.nextSibling);
            };
        } else {
            config.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: white","Please Provide valid selector");
        }
    } else {
        config.debug && console.log("%c[GLOBAL_NAME]", "background: rgb(66, 133, 244); color: white",`Please Provide valid element elementType ${ele_type}`);
    }
}
