import { 
    initTracking, loadAdserverScripts, loadScript
} from './utils';
import slotTypes from './components';
import { AdManager } from './components/manager';




const loadLegacyADV = (config) => {
    const sc = config.siteConfig;

    if (sc._adsense) {
        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'loading adsense:', sc._adsense);
        loadScript('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=' + sc._adsense, { crossorigin: 'anonymous' })
    }

    if (sc._quantum) {
        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'loading quantum');
        loadScript('https://cdn.elasticad.net/native/serve/js/quantx/nativeEmbed.gz.js?checkVisibilityOnTop=true&checkSelectorOnIframe=true');
    }

    if (!!sc._natid) {
        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'loading nativery:', sc._natid);
        window._nat = window._nat || [];
        window._nat.push(['id', sc._natid]);
        loadScript('https://cdn.nativery.com/widget/js/nat.js');
    }

    if (!!sc._teads) {
        if (!!document.getElementById('inRead')) {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'loading teads:', sc._teads);
            loadScript('https://a.teads.tv/page/' + sc._teads + '/tag');
        }
    }

    if (sc._outbrain) {
        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'loading outbrain');
        loadScript('https://widgets.outbrain.com/outbrain.js');
    }

    if (sc._mgid) {
        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'loading MGID');
        loadScript(sc._mgid);
    }

    if (sc._taboola) {
        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", 'loading taboola');
        loadScript(sc._taboola, { id: 'tb_loader_script' });
        if (window.performance && typeof window.performance.mark == 'function') {
            window.performance.mark('tbl_ic');
        }
    }
};

export const initADV = (config) => {

    if (initADV.alreadyRun) {
        console.warn("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "init already run - doing nothing");
        return;
    }
    initADV.alreadyRun = true;

    if (config.customScript) {
        config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "Running custom script");
        try {
            const decodedScript = atob(config.customScript);
            eval(decodedScript);
        } catch (e) {
            console.warn("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "problems running custom script:", e);
        }
    }
    
    /**
     * Init tracking object
     */
    config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "Init tracking");
    initTracking(config)

    /**
     * Load googletag, prebid.js and APS scripts so they'll be ready when auction starts
     */
    if (config.siteConfig._admanager) {
        loadAdserverScripts(config);
    }

    const adManager = new AdManager(config);

    // Expose ad manager object
    window.GLOBAL_OBJECT.manager = adManager;

    // Initialize the ad manager with available slot types
    adManager.initialize(slotTypes);

    loadLegacyADV(config);

    // Set up global function for handling passback of empty slots
    window.GLOBAL_OBJECT.slotEmpty = adManager.getSlotEmptyFunction();

    // Set up all adv positions in page
    adManager.setupSlots(config);

    // Check if GAM needs to run in this page, if so setup and run the initial auction for prebid.js + APS (if available)
    if (config.siteConfig._admanager) {
        adManager.setupAuction(config);
        const initialSlotList = adManager.getGamSlots();
        if (initialSlotList.length > 0) {
            adManager.executeAuction(config, initialSlotList)
        } else {
            config.debug && console.log("%c[GLOBAL_NAME]", "CONSOLE_LOG_STYLE", "No slots - skipping initial auction")
        }
    }
    adManager.listenForEvents(config);
};
