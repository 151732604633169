export const getElapsedTime = (() => {
    let initialTimestamp = null;
    
    return () => {
        if (initialTimestamp === null) {
            initialTimestamp = Date.now();
            return [Math.round(initialTimestamp / 1000), 0]
        }
        
        const currentTime = Date.now();
        return [ Math.round(initialTimestamp / 1000), Math.round((currentTime - initialTimestamp) / 1000)]
    };
})();
