export const getCurrentPageType = () => {
    return window.GLOBAL_OBJECT.config.pageTypes[window.GLOBAL_OBJECT.config.siteConfig.pagetype]
}

export const getPageTypeName = () => {
    return window.GLOBAL_OBJECT.config.siteConfig.pagetype
}


/**
 * If pagetype is not provided or is not a valid one, determine it from the page path or from a css selector
 * Set default from general config, accept any page type flagged as default
 */
export const detectPageType = (config) => {
    let pageType;
    if (!('pagetype' in config.siteConfig) || !(config.siteConfig.pagetype in config.pageTypes)) {
        pageType = config.defaultPageType;
        for (const [pageTypeName, pageTypeObject] of Object.entries(config.pageTypes)) {
            if (
                (pageTypeObject.pathRegex && document.location.pathname.match(pageTypeObject.pathRegex))
                ||
                (pageTypeObject.cssSelector && document.querySelector(pageTypeObject.cssSelector))
            ) {
                pageType = pageTypeName;
                break;
            } else if (pageTypeObject.default) {
                pageType = pageTypeName;
            }
        }
    } else {
        pageType = config.siteConfig.pagetype;
    }
    return pageType;
};



