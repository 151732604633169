export const getUserType = () => {
    return window.GLOBAL_OBJECT.config.siteConfig.userType || null
}

export const isSubscriber = () => {
    const userType = getUserType()
    if (userType && userType != 'non-subscribed' && userType != 'internal') {
        return true
    }
    return false
}